import React, {useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import cn from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';

import {IStore} from 'redux/interface';
import {changeLocale, loadTranslation} from 'redux/translation/translationAsyncActions';

import {languages} from 'utils/constants/constants';
import {RoutesUrl, routeTranslationKeys} from 'utils/constants/routeConstants';

import Chat from 'assets/image/menu-auth/chat.svg';
import Xpedition from 'assets/image/menu-auth/xpedition.svg';
import Backpack from 'assets/image/menu-auth/backpack.svg';
import Library from 'assets/image/menu-auth/lib.svg';
import Materials from 'assets/image/menu-auth/materials.svg';
import language from 'assets/image/header/language-heart.svg';

import '../styles.scss';
import {Journals} from '../../../pages/journals/Journals';
import {Reviews} from '../../../pages/reviews/Reviews';

export const LanguageMenu = (): JSX.Element => {
  const [isOpenLanguageMenu, setIsOpenLanguageMenu] = useState(false);
  const dispatch = useDispatch();

  const {
    translation: {locale}
  } = useSelector((state: IStore) => state);

  function handleChangeLocale(value: string) {
    return () => {
      if (isOpenLanguageMenu) {
        setIsOpenLanguageMenu(false);
      } else {
        setIsOpenLanguageMenu(true);
      }
      dispatch(changeLocale(value));
    };
  }

  const sortLang = languages.reduce<{
    value: string;
    title: string;
    key: string;
  }[]>((acc, item) => {
    if (item.value === locale) {
      return [item, ...acc];
    }
    acc.push(item);

    return acc;
  }, []);

  return <div className="header__language">
    <div className="header__language-wrapper">
      <div
        className={cn('language', {
          'language--open': isOpenLanguageMenu
        })}
      >
        {
          sortLang
            .map((lang, i) => {
              return <button
                key={i}
                className="language__title"
                onClick={handleChangeLocale(lang.value)}
              >{lang.title}</button>;
            })
        }
      </div>
    </div>
    <div className="header__language-item">
      <img className="header__language-item-icon" src={language} alt="" role="presentation"/>
    </div>
  </div>;
};

const navItems = [
  {
    index: 1,
    path: RoutesUrl.Chat,
    title: 'Chat',
    image: Chat,
    isProUser: false
  }//,
  // {
  //   index: 2,
  //   path: RoutesUrl.Xpeditions,
  //   title: 'Xpedition',
  //   image: Xpedition,
  //   isProUser: false
  // },
  // {
  //   index: 3,
  //   path: RoutesUrl.Backpack,
  //   title: 'Backpack',
  //   image: Backpack,
  //   isProUser: false
  // },
  // {
  //   index: 4,
  //   path: RoutesUrl.Materials,
  //   title: 'Materials',
  //   image: Materials,
  //   isProUser: true
  // },
  // {
  //   index: 5,
  //   path: RoutesUrl.Library,
  //   title: 'Library',
  //   image: Library,
  //   isProUser: false
  // }
];

interface INavigationProps {
  className?: string;
  proUser?: boolean;
  onOpenMenu: () => void;
}

export const Navigation = ({
  className,
  proUser,
  onOpenMenu
}: INavigationProps): JSX.Element => {
  const {
    translationKeys
  } = useSelector((state: IStore) => state.translation);

  const location = useLocation();
  const dispatch = useDispatch();

  function setActiveSection(pathname: string) {
    if (location.pathname === RoutesUrl.Content || location.pathname === RoutesUrl.Dialogue) {
      return !!location.state?.parentLocation.includes(pathname.substring(1));
    }

    return location.pathname === pathname;
  }

  function handleMenuItemClick(route: string) {
    return () => {
      if (!translationKeys.includes(routeTranslationKeys[route])) {
        dispatch(loadTranslation(routeTranslationKeys[route]));
      }
      setActiveSection(route);
      onOpenMenu();
    };
  }

  function handleSetActiveSection(pathname: string) {
    return () => setActiveSection(pathname);
  }

  return (<nav className={cn('nav', className)}>
    {
      navItems.map((item, index) => {
        if (!proUser && item.isProUser) {
          return null;
        }

        return (<div key={index} className="nav__item">
          <NavLink
            to={item.path}
            className={(nav)=>nav.isActive ? 'nav__link nav__link-active' : 'nav__link'}
            onClick={handleMenuItemClick(item.path)}
            // isActive={handleSetActiveSection(item.path)}
          >
            <div className="nav__item-wrapper">
              <div className="nav__img-wrapper">
                <img className="nav__img" src={item.image} alt="" role="presentation"/>
              </div>
              <p className="nav__item-text">
                <FormattedMessage
                  id={`gritx.common.navigation.${item.title}`}
                  defaultMessage={item.title}
                />
              </p>
            </div>
          </NavLink>
        </div>);
      })
    }
  </nav>);
};
